import {
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MUIHead,
  TableRow,
  Typography,
  Box,
  LinearProgress,
} from '@mui/material';
import { toggleSystemSummaryReport } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Button, Modal } from 'shared/ui';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { useSystemSummaryReportData } from './hooks';
import { usePdfExport } from 'shared/hooks';

const TableHead = styled(MUIHead)(({ theme }) => ({
  th: {
    color: theme.palette.primary.main,
    backgroundColor: '#F5FBFF',
  },
}));
const scrollStyles = {
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'primary.main',
  },
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    backgroundColor: '#F5FBFF',
  },
};

export const SystemSummaryReport = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openSystemSummaryReport } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((st) => st.units);
  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submainValues } = useAppSelector((st) => st.submainState);
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);
  const { laterals } = useAppSelector((st) => st.laterals);
  const { emitters } = useAppSelector((st) => st.emitters);
  const { mainlines } = useAppSelector((st) => st.mainlines);
  const { submains } = useAppSelector((st) => st.submains);

  const currentProduct = projectData.integrated
    ? laterals.find((item) => item.CATLOG === projectData.lateralCatalog)
    : emitters.find((item) => item.CATLOG === projectData.emitterCatalog);

  const pressureAtHead = Math_round(
    mainlineValues.pumpPressure && mainlineValues.pipesHeadloss
      ? mainlineValues.pumpPressure - mainlineValues.pipesHeadloss
      : 0,
    3
  );

  const pressureAtEnd = Math_round(
    lateralValues.resultReportArray[lateralValues.resultReportArray.length - 1]?.inletPressure ?? 0,
    3
  );

  const toggle = () => dispatch(toggleSystemSummaryReport());

  const { infoData, pdfTableData } = useSystemSummaryReportData();
  const { isGeneratingPDF, loadingProgress, handleDownloadPDF } = usePdfExport({
    type: 'summary',
    title: 'SystemSummary Report',
    infoData,
    tables: pdfTableData,
  });

  return (
    <Modal maxWidth="lg" title="SystemSummary Report" open={openSystemSummaryReport} onClose={toggle}>
      <Grid container rowGap={1}>
        <Grid item xs={12}>
          <Button onClick={handleDownloadPDF} disabled={isGeneratingPDF} sx={{ width: '200px' }}>
            {t('export')}
          </Button>

          {isGeneratingPDF && (
            <Box mt={2}>
              <LinearProgress variant="determinate" value={loadingProgress} />
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <TableContainer sx={{ ...scrollStyles }}>
            <Table sx={{ width: '500px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('preparedFor')}</TableCell>
                  <TableCell>{t('by')}</TableCell>
                  <TableCell>{t('_date')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{`${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`}</TableCell>
                  <TableCell>{username}</TableCell>
                  <TableCell>{new Date(Date.now()).toLocaleString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center">{t('lateralRep')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <TableContainer
            sx={{
              maxHeight: 430,
              overflow: 'auto',
              ...scrollStyles,
            }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{t('emitterConstant')}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('emitterExponent')}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('emitterSpacing')} (${units.length})`}</TableCell>
                  <TableCell>{projectData.emitterSpacing}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('nominalFlow')} (${units.flow})`}</TableCell>
                  <TableCell>{projectData.emitterNominalFlow}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('nominalPr')} (${units.pressure})`}</TableCell>
                  <TableCell>{projectData.emitterNominalPressure}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('lateralCatalog')}</TableCell>
                  <TableCell>{currentProduct?.CATLOGDESC}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('internalDia')} (${units.pipeDiameter})`}</TableCell>
                  <TableCell>{projectData.lateralInletDia}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('kd')}</TableCell>
                  <TableCell>{projectData.lateralKd}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('lateralRep')}</TableCell>
                  <TableCell>{projectData.lateralLength2}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('slope')} (%)`}</TableCell>
                  <TableCell>{projectData.mainlineSlope}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('inletPr')} ${units.pressure}`}</TableCell>
                  <TableCell>{projectData.lateralInletPressure}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('lateralFlow')} ${units.totalFlow}`}</TableCell>
                  <TableCell>{lateralValues.resultTotalFlow}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('eu')}</TableCell>
                  <TableCell>{lateralValues.resultEU}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('Qmin/Qmax')}</TableCell>
                  <TableCell>{lateralValues.resultQMinQMax ?? 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('Du')}</TableCell>
                  <TableCell>{lateralValues.resultMaxLengthDU ?? 0}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('flushingEndVelocity')} ${units.velocity}`}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('flushingEndPr')} ${units.pressure}`}</TableCell>
                  <TableCell>{''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center">{t('manifoldRep')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <TableContainer
            sx={{
              maxHeight: 430,
              overflow: 'auto',
              ...scrollStyles,
            }}
          >
            <Table>
              <TableHead sx={{ color: 'primary.main' }}>
                <TableRow>
                  <TableCell>{t('catalog')}</TableCell>
                  <TableCell>{`${t('length')} (${units.length})`}</TableCell>
                  <TableCell>{`${t('dia')} (${units.pipeDiameter})`}</TableCell>
                  <TableCell>{`${t('velocity')} (${units.velocity})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectData.manifoldPipe1Length ? (
                  <TableRow>
                    <TableCell>
                      {submains.find((item) => item.CATLOG === projectData.manifoldPipe1)?.CATLOGDESC}
                    </TableCell>
                    <TableCell>{projectData.manifoldPipe1Length}</TableCell>
                    <TableCell>{projectData.manifoldPipe1Dia}</TableCell>
                    <TableCell>{submainValues.velocity1}</TableCell>
                  </TableRow>
                ) : null}
                {projectData.manifoldPipe2Length ? (
                  <TableRow>
                    <TableCell>
                      {submains.find((item) => item.CATLOG === projectData.manifoldPipe2)?.CATLOGDESC}
                    </TableCell>
                    <TableCell>{projectData.manifoldPipe2Length}</TableCell>
                    <TableCell>{projectData.manifoldPipe2Dia}</TableCell>
                    <TableCell>{submainValues.velocity2}</TableCell>
                  </TableRow>
                ) : null}
                {projectData.manifoldPipe3Length ? (
                  <TableRow>
                    <TableCell>
                      {submains.find((item) => item.CATLOG === projectData.manifoldPipe3)?.CATLOGDESC}
                    </TableCell>
                    <TableCell>{projectData.manifoldPipe3Length}</TableCell>
                    <TableCell>{projectData.manifoldPipe3Dia}</TableCell>
                    <TableCell>{submainValues.velocity3}</TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{`${t('flowRate')} (${units.totalFlow})`}</TableCell>
                  <TableCell>{`${t('headloss')} (${units.pressure})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{projectData.totalFlow}</TableCell>
                  <TableCell>{projectData.manifoldHeadloss}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center">{t('mainlineRep')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <TableContainer
            sx={{
              maxHeight: 430,
              overflow: 'auto',
              ...scrollStyles,
            }}
          >
            <Table>
              <TableHead sx={{ color: 'primary.main' }}>
                <TableRow>
                  <TableCell>{t('catalog')}</TableCell>
                  <TableCell>{`${t('length')} (${units.length})`}</TableCell>
                  <TableCell>{`${t('dia')} (${units.pipeDiameter})`}</TableCell>
                  <TableCell>{`${t('velocity')} (${units.velocity})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectData.mainlinePipe1Length ? (
                  <TableRow>
                    <TableCell>
                      {mainlines.find((item) => item.CATLOG === projectData.mainlinePipe1)?.CATLOGDESC}
                    </TableCell>
                    <TableCell>{projectData.mainlinePipe1Length}</TableCell>
                    <TableCell>{projectData.mainlinePipe1Dia}</TableCell>
                    <TableCell>{mainlineValues.velocity1}</TableCell>
                  </TableRow>
                ) : null}
                {projectData.mainlinePipe2Length ? (
                  <TableRow>
                    <TableCell>
                      {mainlines.find((item) => item.CATLOG === projectData.mainlinePipe2)?.CATLOGDESC}
                    </TableCell>
                    <TableCell>{projectData.mainlinePipe2Length}</TableCell>
                    <TableCell>{projectData.mainlinePipe2Dia}</TableCell>
                    <TableCell>{mainlineValues.velocity2}</TableCell>
                  </TableRow>
                ) : null}
                {projectData.mainlinePipe3Length ? (
                  <TableRow>
                    <TableCell>
                      {mainlines.find((item) => item.CATLOG === projectData.mainlinePipe3)?.CATLOGDESC}
                    </TableCell>
                    <TableCell>{projectData.mainlinePipe3Length}</TableCell>
                    <TableCell>{projectData.mainlinePipe3Dia}</TableCell>
                    <TableCell>{mainlineValues.velocity3}</TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{`${t('flowRate')} (${units.totalFlow})`}</TableCell>
                  <TableCell>{`${t('headloss')} (${units.pressure})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{projectData.totalFlow}</TableCell>
                  <TableCell>{projectData.mainlinePipeHeadloss}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center">{t('systemRep')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <TableContainer
            sx={{
              maxHeight: 430,
              overflow: 'auto',
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'primary.main',
              },
              '&::-webkit-scrollbar': {
                width: '6px',
                backgroundColor: '#F5FBFF',
              },
            }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{`${t('pumpPressure')} (${units.pressure})`}</TableCell>
                  <TableCell>{projectData.mainlinePumpPressure}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('pumpHeadloss')} (${units.pressure})`}</TableCell>
                  <TableCell>{mainlineValues.pumpHeadloss}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('pressureAtHead')} (${units.pressure})`}</TableCell>
                  <TableCell>{isNaN(pressureAtHead) ? 0 : pressureAtHead}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('pipesHeadloss')} (${units.pressure})`}</TableCell>
                  <TableCell>{projectData.mainlinePipeHeadloss}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('pressureAtValve')} (${units.pressure})`}</TableCell>
                  <TableCell>{projectData.mainlinePressureAtValve}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('valveHeadloss')} (${units.pressure})`}</TableCell>
                  <TableCell>{mainlineValues.valveHeadloss}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('prAtManifoldInlet')} (${units.pressure})`}</TableCell>
                  <TableCell>{projectData.valvePressure}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('manifoldHeadloss')} (${units.pressure})`}</TableCell>
                  <TableCell>{projectData.manifoldHeadloss}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('inletPr')} (${units.pressure})`}</TableCell>
                  <TableCell>{projectData.lateralInletPressure}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('lateralHL')} (${units.pressure})`}</TableCell>
                  <TableCell>
                    {lateralValues.resultTotalHeadloss || lateralValues.resultMaxLengthTotalHeadloss || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{`${t('prAtLateralEnd')} (${units.pressure})`}</TableCell>
                  <TableCell>{isNaN(pressureAtEnd) ? 0 : pressureAtEnd}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} maxWidth="100%">
          <Typography variant="body2">{t('repDisclaimer')}</Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};
