import { Table } from 'widgets/PdfExport/PdfExport';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { Math_round } from 'shared/lib/calculation/mathRound';

export const useSystemSummaryReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submainValues } = useAppSelector((st) => st.submainState);
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);
  const { submains } = useAppSelector((st) => st.submains);
  const { mainlines } = useAppSelector((st) => st.mainlines);

  const pressureAtEnd = Math_round(
    lateralValues.resultReportArray[lateralValues.resultReportArray.length - 1]?.inletPressure ?? 0,
    3
  );

  const infoData = {
    data: [
      { header: t('preparedFor'), values: [`${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`] },
      { header: t('by'), values: [username] },
      { header: t('_date'), values: [new Date(Date.now()).toLocaleString()] },
    ],
  };

  const pdfTableData: Table[] = useMemo(() => {
    const lateralReport = {
      title: t('lateralRep'),
      data: [
        { header: t('emitterConstant'), values: [''] },
        { header: t('emitterExponent'), values: [''] },
        { header: `${t('emitterSpacing')} (${units.length})`, values: [projectData.emitterSpacing] },
        { header: `${t('nominalFlow')} (${units.flow})`, values: [projectData.emitterNominalFlow] },
        { header: `${t('nominalPr')} (${units.pressure})`, values: [projectData.emitterNominalPressure] },
        { header: `${t('internalDia')} (${units.pipeDiameter})`, values: [projectData.lateralInletDia] },
        { header: t('kd'), values: [projectData.lateralKd] },
        { header: t('lateralRep'), values: [projectData.lateralLength2] },
        { header: `${t('slope')} (%)`, values: [projectData.mainlineSlope] },
        { header: `${t('inletPr')} ${units.pressure}`, values: [projectData.lateralInletPressure] },
        { header: `${t('lateralFlow')} ${units.totalFlow}`, values: [lateralValues.resultTotalFlow ?? 0] },
        { header: t('eu'), values: [lateralValues.resultEU ?? ''] },
        { header: t('Qmin/Qmax'), values: [lateralValues.resultQMinQMax ?? ''] },
        { header: t('Du'), values: [lateralValues.resultMaxLengthDU ?? ''] },
        { header: `${t('flushingEndVelocity')} ${units.velocity}`, values: [''] },
        { header: `${t('flushingEndPr')} ${units.pressure}`, values: [''] },
      ],
    };

    const manifoldDescriptions = [
      submains.find((item) => item.CATLOG === projectData.manifoldPipe1)?.CATLOGDESC ?? '',
      submains.find((item) => item.CATLOG === projectData.manifoldPipe2)?.CATLOGDESC ?? '',
      submains.find((item) => item.CATLOG === projectData.manifoldPipe3)?.CATLOGDESC ?? '',
    ];

    const manifoldReport = {
      title: t('manifoldRep'),
      data: [
        { header: t('catalog'), values: manifoldDescriptions },
        {
          header: `${t('length')} (${units.length})`,
          values: [projectData.manifoldPipe1Length, projectData.manifoldPipe2Length, projectData.manifoldPipe3Length],
        },
        {
          header: `${t('dia')} (${units.pipeDiameter})`,
          values: [projectData.manifoldPipe1Dia, projectData.manifoldPipe2Dia, projectData.manifoldPipe3Dia],
        },
        {
          header: `${t('veloctiy')} (${units.velocity})`,
          values: [submainValues.velocity1, submainValues.velocity2, submainValues.velocity3],
        },
      ],
    };

    const generalDataManifold = {
      data: [
        { header: t('flowRate'), values: [projectData.totalFlow ?? ''] },
        { header: t('headloss'), values: [projectData.manifoldHeadloss ?? ''] },
      ],
    };

    const mainlineDescriptions = [
      mainlines.find((item) => item.CATLOG === projectData.mainlinePipe1)?.CATLOGDESC ?? '',
      mainlines.find((item) => item.CATLOG === projectData.manifoldPipe2)?.CATLOGDESC ?? '',
      mainlines.find((item) => item.CATLOG === projectData.manifoldPipe3)?.CATLOGDESC ?? '',
    ];

    const mainlineReport = {
      title: t('mainlineRep'),
      data: [
        { header: t('catalog'), values: mainlineDescriptions },
        {
          header: `${t('length')} (${units.length})`,
          values: [projectData.mainlinePipe1Length, projectData.mainlinePipe2Length, projectData.mainlinePipe3Length],
        },
        {
          header: `${t('dia')} (${units.pipeDiameter})`,
          values: [projectData.mainlinePipe1Dia, projectData.mainlinePipe2Dia, projectData.mainlinePipe3Dia],
        },
        {
          header: `${t('veloctiy')} (${units.velocity})`,
          values: [mainlineValues.velocity1, mainlineValues.velocity2, mainlineValues.velocity3],
        },
      ],
    };

    const generalDataManiline = {
      data: [
        { header: t('flowRate'), values: [projectData.totalFlow ?? ''] },
        { header: t('headloss'), values: [projectData.mainlinePipeHeadloss ?? ''] },
      ],
    };

    const systemReport = {
      title: t('systemRep'),
      data: [
        { header: `${t('pumpPressure')} (${units.pressure})`, values: [projectData.mainlinePumpPressure] },
        { header: `${t('pumpHeadloss')} (${units.pressure})`, values: [mainlineValues.pumpHeadloss] },
        {
          header: `${t('pressureAtHead')} (${units.pressure})`,
          values: [+projectData.mainlinePumpPressure - +mainlineValues.pumpHeadloss],
        },
        { header: `${t('pipesHeadloss')} (${units.pressure})`, values: [projectData.mainlinePipeHeadloss] },
        { header: `${t('nominalPr')} (${units.pressure})`, values: [projectData.emitterNominalPressure] },
        { header: `${t('pressureAtValve')} (${units.pressure})`, values: [projectData.mainlinePressureAtValve] },
        { header: `${t('valveHeadloss')} (${units.pressure})`, values: [mainlineValues.valveHeadloss] },
        { header: `${t('prAtManifoldInlet')} (${units.pressure})`, values: [projectData.valvePressure] },
        { header: `${t('manifoldHeadloss')} (${units.pressure})`, values: [projectData.manifoldHeadloss] },
        { header: `${t('inletPr')} (${units.pressure})`, values: [projectData.lateralInletPressure] },
        {
          header: `${t('lateralHL')} (${units.pressure})`,
          values: [lateralValues.resultTotalHeadloss || lateralValues.resultMaxLengthTotalHeadloss || 0],
        },
        { header: `${t('prAtLateralEnd')} (${units.pressure})`, values: [isNaN(pressureAtEnd) ? 0 : pressureAtEnd] },
      ],
    };

    return [lateralReport, manifoldReport, generalDataManifold, mainlineReport, generalDataManiline, systemReport];
  }, [units, projectData, mainlineValues]);

  return {
    infoData,
    pdfTableData,
  };
};
