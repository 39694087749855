import {
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MUIHead,
  TableRow,
  Typography,
  Box,
  LinearProgress,
} from '@mui/material';
import { toggleLateralDefineLengthReport } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Button, Modal } from 'shared/ui';
import { parseSlopes } from 'shared/lib';
import { useDefineLengthReportData } from './hooks';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { usePdfExport } from 'shared/hooks';

const TableHead = styled(MUIHead)(({ theme }) => ({
  th: {
    color: theme.palette.primary.main,
    backgroundColor: '#F5FBFF',
  },
}));

const scrollStyles = {
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'primary.main',
  },
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    backgroundColor: '#F5FBFF',
  },
};

export const LateralDefineLengthReport = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { openLateralDefineLengthReport } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((st) => st.units);
  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { laterals } = useAppSelector((st) => st.laterals);
  const { emitters } = useAppSelector((st) => st.emitters);

  const currentProduct = projectData.integrated
    ? laterals.find((item) => item.CATLOG === projectData.lateralCatalog)
    : emitters.find((item) => item.CATLOG === projectData.emitterCatalog);

  const toggle = () => dispatch(toggleLateralDefineLengthReport());

  const { infoData, pdfTableData } = useDefineLengthReportData();
  const { isGeneratingPDF, loadingProgress, handleDownloadPDF } = usePdfExport({
    type: 'lateral',
    title: t('lateralDesignReport'),
    currentProduct,
    infoData,
    tables: pdfTableData,
  });

  return (
    <Modal maxWidth="lg" title={`${t('lateralDesignReport')}`} open={openLateralDefineLengthReport} onClose={toggle}>
      <Grid container rowGap={1}>
        <Grid item xs={12}>
          <Button onClick={handleDownloadPDF} disabled={isGeneratingPDF} sx={{ width: '200px' }}>
            {t('export')}
          </Button>

          {isGeneratingPDF && (
            <Box mt={2}>
              <LinearProgress variant="determinate" value={loadingProgress} />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} maxWidth="100%">
          <TableContainer sx={{ ...scrollStyles }}>
            <Table sx={{ width: '500px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('preparedFor')}</TableCell>
                  <TableCell>{t('by')}</TableCell>
                  <TableCell>{t('_date')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{`${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`}</TableCell>
                  <TableCell>{username}</TableCell>
                  <TableCell>{new Date(Date.now()).toLocaleString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" sx={{ pl: 2 }}>
            {currentProduct?.CATLOGDESC}
          </Typography>
        </Grid>

        <Grid item xs={12} maxWidth="100%">
          <TableContainer sx={{ ...scrollStyles }}>
            <Table>
              <TableHead sx={{ color: 'primary.main' }}>
                <TableRow>
                  <TableCell>{`${t('inletPr')} (${units.pressure})`}</TableCell>
                  <TableCell>{`${t('internalDia')} (${units.pipeDiameter})`}</TableCell>
                  <TableCell>{`${t('slope')} (%)`}</TableCell>
                  <TableCell>{`${t('pMin')} (${units.pressure})`}</TableCell>
                  <TableCell>{`${t('pMax')} (${units.pressure})`}</TableCell>
                  <TableCell>{`${t('nominalPr')} (${units.pressure})`}</TableCell>
                  <TableCell>{`${t('nominalFlow')} (${units.flow})`}</TableCell>
                  <TableCell>{`${t('flushingVelocity')} (${units.velocity})`}</TableCell>
                  <TableCell>{`${t('eu')} (%)`}</TableCell>
                  <TableCell>{`${t('Du')} (%)`}</TableCell>
                  <TableCell>{`${t('Qmin/Qmax')}`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{projectData.lateralInletPressure}</TableCell>
                  <TableCell>{projectData.lateralInletDia}</TableCell>
                  <TableCell>{parseSlopes(projectData.lateralSlopes ?? '')[0]?.slope ?? 0}</TableCell>
                  <TableCell>{lateralValues.resultPMin}</TableCell>
                  <TableCell>{lateralValues.resultPMax}</TableCell>
                  <TableCell>{projectData.emitterNominalFlow}</TableCell>
                  <TableCell>{projectData.emitterNominalPressure}</TableCell>
                  <TableCell>{projectData.flushingVelocity ?? 0}</TableCell>
                  <TableCell> {lateralValues.resultEU}</TableCell>
                  <TableCell>{Math_round((lateralValues.resultDU as number) * 100, 3)}</TableCell>
                  <TableCell>{Math_round(lateralValues.resultQMinQMax as number, 3)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <TableContainer
            sx={{
              maxHeight: 430,
              overflow: 'auto',
              ...scrollStyles,
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      bgcolor: 'red',
                    }}
                  >{`${t('length')} (${units.length})`}</TableCell>
                  <TableCell>{`${t('inletPr')} (${units.pressure})`}</TableCell>
                  <TableCell>{`${t('lateralFlow')} (${units.totalFlow})`}</TableCell>
                  <TableCell>{`${t('emitterFlow')} (${units.flow})`}</TableCell>
                  <TableCell>{`${t('velocity')} (${units.velocity})`}</TableCell>
                  <TableCell>{`${t('headloss')} (${units.pressure})`}</TableCell>
                  <TableCell>{`${t('totalHeadloss')} (${units.pressure})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lateralValues.resultReportArray.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{item.length}</TableCell>
                    <TableCell>{item.inletPressure}</TableCell>
                    <TableCell>{item.lateralFlow}</TableCell>
                    <TableCell>{item.averageFlow}</TableCell>
                    <TableCell>{item.velocity}</TableCell>
                    <TableCell>{item.headloss}</TableCell>
                    <TableCell>{item.totalHeadloss.toString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="body2">{t('repDisclaimer')}</Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};
