import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { Table } from 'widgets/PdfExport';

export const useMainlineReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);

  const infoData = {
    data: [
      { header: t('preparedFor'), values: [`${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`] },
      { header: t('by'), values: [username] },
      { header: t('_date'), values: [new Date(Date.now()).toLocaleString()] },
    ],
  };

  const pdfTableData: Table[] = useMemo(() => {
    const firstTableData = {
      data: [
        {
          header: t('catalog'),
          values: [projectData.mainlinePipe1, projectData.mainlinePipe2, projectData.mainlinePipe3],
        },
        {
          header: `${t('length')} (${units.length})`,
          values: [projectData.mainlinePipe1Length, projectData.mainlinePipe2Length, projectData.mainlinePipe3Length],
        },
        {
          header: `${t('dia')} (${units.pipeDiameter})`,
          values: [projectData.mainlinePipe1Dia, projectData.mainlinePipe2Dia, projectData.mainlinePipe3Dia],
        },
        {
          header: `${t('veloctiy')} (${units.velocity})`,
          values: [mainlineValues.velocity1, mainlineValues.velocity2, mainlineValues.velocity3],
        },
      ],
    };

    const secondTableData = {
      data: [
        { header: t('totalFlow'), values: [projectData.totalFlow ?? ''] },
        { header: t('maxVelocity'), values: [mainlineValues.maxVelocity ?? ''] },
        { header: t('headloss'), values: [projectData.mainlinePipeHeadloss ?? ''] },
      ],
    };

    return [firstTableData, secondTableData];
  }, [units, projectData, mainlineValues]);

  return {
    infoData,
    pdfTableData,
  };
};
