import {
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MUIHead,
  TableRow,
  Typography,
  LinearProgress,
  Box,
} from '@mui/material';
import { toggleSubmainReport } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Button, Modal } from 'shared/ui';
import { useSubmainReportData } from './hooks';
import { usePdfExport } from 'shared/hooks';

const TableHead = styled(MUIHead)(({ theme }) => ({
  th: {
    color: theme.palette.primary.main,
    backgroundColor: '#F5FBFF',
  },
}));

const scrollStyles = {
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'primary.main',
  },
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    backgroundColor: '#F5FBFF',
  },
};

export const SubmainReport = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openSubmainReport } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((st) => st.units);
  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submains } = useAppSelector((st) => st.submains);
  const { submainValues } = useAppSelector((st) => st.submainState);

  const toggle = () => dispatch(toggleSubmainReport());

  const { infoData, pdfTableData } = useSubmainReportData();
  const { isGeneratingPDF, loadingProgress, handleDownloadPDF } = usePdfExport({
    type: 'submain',
    title: t('submainReport'),
    infoData,
    tables: pdfTableData,
  });

  return (
    <Modal maxWidth="lg" title={t('submainReport')} open={openSubmainReport} onClose={toggle}>
      <Grid container rowGap={1}>
        <Grid item xs={12}>
          <Button onClick={handleDownloadPDF} disabled={isGeneratingPDF} sx={{ width: '200px' }}>
            {t('export')}
          </Button>

          {isGeneratingPDF && (
            <Box mt={2}>
              <LinearProgress variant="determinate" value={loadingProgress} />
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <TableContainer sx={{ ...scrollStyles }}>
            <Table sx={{ width: '500px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('preparedFor')}</TableCell>
                  <TableCell>{t('by')}</TableCell>
                  <TableCell>{t('_date')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{`${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`}</TableCell>
                  <TableCell>{username}</TableCell>
                  <TableCell>{new Date(Date.now()).toLocaleString()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">{t('manifoldRep')}</Typography>
        </Grid>

        <Grid item xs={12} maxWidth="100%">
          <TableContainer sx={{ ...scrollStyles }}>
            <Table>
              <TableHead sx={{ color: 'primary.main' }}>
                <TableRow>
                  <TableCell>{t('catalog')}</TableCell>
                  <TableCell>{`${t('length')} (${units.length})`}</TableCell>
                  <TableCell>{`${t('dia')} (${units.pipeDiameter})`}</TableCell>
                  <TableCell>{`${t('velocity')} (${units.velocity})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectData.manifoldPipe1Length ? (
                  <TableRow>
                    <TableCell>
                      {submains.find((item) => item.CATLOG === projectData.manifoldPipe1)?.CATLOGDESC}
                    </TableCell>
                    <TableCell>{projectData.manifoldPipe1Length}</TableCell>
                    <TableCell>{projectData.manifoldPipe1Dia}</TableCell>
                    <TableCell>{submainValues.velocity1}</TableCell>
                  </TableRow>
                ) : null}
                {projectData.manifoldPipe2Length ? (
                  <TableRow>
                    <TableCell>
                      {submains.find((item) => item.CATLOG === projectData.manifoldPipe2)?.CATLOGDESC}
                    </TableCell>
                    <TableCell>{projectData.manifoldPipe2Length}</TableCell>
                    <TableCell>{projectData.manifoldPipe2Dia}</TableCell>
                    <TableCell>{submainValues.velocity2}</TableCell>
                  </TableRow>
                ) : null}
                {projectData.manifoldPipe3Length ? (
                  <TableRow>
                    <TableCell>
                      {submains.find((item) => item.CATLOG === projectData.manifoldPipe3)?.CATLOGDESC}
                    </TableCell>
                    <TableCell>{projectData.manifoldPipe3Length}</TableCell>
                    <TableCell>{projectData.manifoldPipe3Dia}</TableCell>
                    <TableCell>{submainValues.velocity3}</TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <TableContainer sx={{ maxHeight: 430 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{`${t('pressureAtValve')} (${units.length})`}</TableCell>
                  <TableCell>{`${t('disTo1stLateral')} (${units.length})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{submainValues.valvePressure}</TableCell>
                  <TableCell>{submainValues.firstLateral}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <TableContainer
            sx={{
              maxHeight: 430,
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'primary.main',
              },
              '&::-webkit-scrollbar': {
                width: '6px',
                height: '6px',
                backgroundColor: '#F5FBFF',
              },
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>{`${t('length')} (${units.length})`}</TableCell>
                  <TableCell>{`${t('flow')} (${units.totalFlow})`}</TableCell>
                  <TableCell>{`${t('velocity')} (${units.velocity})`}</TableCell>
                  <TableCell>{`${t('headloss')} (${units.length})`}</TableCell>
                  <TableCell>{`${t('Pressure')} (${units.pressure})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submainValues.resultReportArray.map((item) => (
                  <TableRow key={item.len}>
                    <TableCell>{item.len}</TableCell>
                    <TableCell>{item.flow}</TableCell>
                    <TableCell>{isNaN(item.vel) ? '' : item.vel}</TableCell>
                    <TableCell>{isNaN(item.headloss) ? '' : item.headloss}</TableCell>
                    <TableCell>{item.pressure}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2">{t('repDisclaimer')}</Typography>
        </Grid>
      </Grid>
    </Modal>
  );
};
