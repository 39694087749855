import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { Table } from 'widgets/PdfExport';

export const useSubmainReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submainValues } = useAppSelector((st) => st.submainState);

  const infoData = {
    data: [
      { header: t('preparedFor'), values: [`${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`] },
      { header: t('by'), values: [username] },
      { header: t('_date'), values: [new Date(Date.now()).toLocaleString()] },
    ],
  };

  const pdfTableData: Table[] = useMemo(() => {
    const firstTableData = {
      data: [
        {
          header: t('catalog'),
          values: [projectData.manifoldPipe1, projectData.manifoldPipe2, projectData.manifoldPipe3],
        },
        {
          header: `${t('length')} (${units.length})`,
          values: [projectData.manifoldPipe1Length, projectData.manifoldPipe2Length, projectData.manifoldPipe3Length],
        },
        {
          header: `${t('dia')} (${units.pipeDiameter})`,
          values: [projectData.manifoldPipe1Dia, projectData.manifoldPipe2Dia, projectData.manifoldPipe3Dia],
        },
        {
          header: `${t('veloctiy')} (${units.velocity})`,
          values: [submainValues.velocity1, submainValues.velocity2, submainValues.velocity3],
        },
      ],
    };

    const secondTableData = {
      data: [
        { header: t('pressureAtValve'), values: [submainValues.valvePressure ?? ''] },
        { header: t('disTo1stLateral'), values: [submainValues.firstLateral ?? ''] },
      ],
    };

    const thirdTableData = {
      data: [
        {
          header: `${t('length')} (${units.length})`,
          values: submainValues.resultReportArray?.map((item) => item.len),
        },
        {
          header: `${t('flow')} (${units.totalFlow})`,
          values: submainValues.resultReportArray?.map((item) => item.flow),
        },
        {
          header: `${t('velocity')} (${units.velocity})`,
          values: submainValues.resultReportArray?.map((item) => item.vel),
        },
        {
          header: `${t('headloss')} (${units.length})`,
          values: submainValues.resultReportArray?.map((item) => item.headloss),
        },
        {
          header: `${t('pressure')} (${units.pressure})`,
          values: submainValues.resultReportArray?.map((item) => item.pressure),
        },
      ],
    };

    return [firstTableData, secondTableData, thirdTableData];
  }, [units, projectData, submainValues]);

  return {
    infoData,
    pdfTableData,
  };
};
