import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { parseSlopes } from 'shared/lib';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { Table } from 'widgets/PdfExport';

export const useMaxLengthReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { lateralValues } = useAppSelector((st) => st.lateralState);

  const infoData = {
    data: [
      { header: t('preparedFor'), values: [`${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`] },
      { header: t('by'), values: [username] },
      { header: t('_date'), values: [new Date(Date.now()).toLocaleString()] },
    ],
  };

  const pdfTableData: Table[] = useMemo(() => {
    const firstTableData = {
      data: [
        { header: `${t('inletPr')} (${units.pressure})`, values: [projectData.lateralInletPressure] },
        { header: `${t('internalDia')} (${units.pipeDiameter})`, values: [projectData.lateralInletDia] },
        { header: `${t('slope')} (%)`, values: [parseSlopes(projectData.lateralSlopes ?? '')[0]?.slope ?? 0] },
        { header: `${t('pMin')} (${units.pressure})`, values: [lateralValues.resultMaxLengthPMin] },
        { header: `${t('pMax')} (${units.pressure})`, values: [lateralValues.resultMaxLengthPMax] },
        { header: `${t('nominalPr')} (${units.pressure})`, values: [projectData.emitterNominalFlow] },
        { header: `${t('nominalFlow')} (${units.flow})`, values: [projectData.emitterNominalPressure] },
        { header: `${t('flushingVelocity')} (${units.velocity})`, values: [projectData.flushingVelocity ?? 0] },
        { header: `${t('eu')} (%)`, values: [lateralValues.resultMaxLengthEU] },
        { header: `${t('Du')} (%)`, values: [Math_round((lateralValues.resultMaxLengthDU as number) * 100, 3)] },
        { header: `${t('Qmin/Qmax')}`, values: [Math_round(lateralValues.resultMaxLengthQMinQMax as number, 3)] },
      ],
    };

    const secondTableData = {
      data: [
        {
          header: `${t('length')} (${units.length})`,
          values: lateralValues.resultMaxLengthReportArray?.map((item) => item.length),
        },
        {
          header: `${t('inletPr')} (${units.pressure})`,
          values: lateralValues.resultMaxLengthReportArray?.map((item) => item.inletPressure),
        },
        {
          header: `${t('lateralFlow')} (${units.totalFlow})`,
          values: lateralValues.resultMaxLengthReportArray?.map((item) => item.lateralFlow),
        },
        {
          header: `${t('emitterFlow')} (${units.flow})`,
          values: lateralValues.resultMaxLengthReportArray?.map((item) => item.averageFlow),
        },
        {
          header: `${t('velocity')} (${units.velocity})`,
          values: lateralValues.resultMaxLengthReportArray?.map((item) => item.velocity),
        },
        {
          header: `${t('headloss')} (${units.pressure})`,
          values: lateralValues.resultMaxLengthReportArray?.map((item) => item.headloss),
        },
        {
          header: `${t('totalHeadloss')} (${units.pressure})`,
          values: lateralValues.resultMaxLengthReportArray?.map((item) => item.totalHeadloss.toString()),
        },
      ],
    };

    return [firstTableData, secondTableData];
  }, [units, projectData, lateralValues]);

  return {
    infoData,
    pdfTableData,
  };
};
