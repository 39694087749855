import { wrap } from 'comlink';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTableRowsNumber } from 'shared/lib';
import { WorkerType } from 'shared/workers';
import { PDFProps, Table } from 'widgets/PdfExport';

export const usePdfExport = (pdfProps: PDFProps) => {
  const { type, tables } = pdfProps;

  const { t } = useTranslation();

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const translationProps = {
    ...(type === 'submain' && { tableName: t('manifoldRep') }),
    ...(type === 'mainline' && { tableName: t('mainlineRep') }),
    disclaimer: t('repDisclaimer'),
  };

  const handleDownloadPDF = async () => {
    setIsGeneratingPDF(true);

    const workerInstance = new Worker(new URL('../workers/pdfWorker.worker.ts', import.meta.url), { type: 'module' });
    const worker = wrap<WorkerType>(workerInstance);

    const tableRowsNumber = getTableRowsNumber(tables);
    let progress = 0;

    const progressInterval = setInterval(() => {
      // approximately 400 rows is loaded in 0.5s
      progress += (400 * 100) / tableRowsNumber;
      if (progress > 100) progress = 100;

      setLoadingProgress(progress);

      if (progress >= 100) {
        clearInterval(progressInterval);
      }
    }, 500);

    try {
      const tablesArrayBuffer = convertTablesToBuffer(tables);

      const blobUrl = await worker.renderPDFInWorker({
        ...pdfProps,
        tables: tablesArrayBuffer,
        translations: translationProps,
      });

      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'report.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsGeneratingPDF(false);
      clearInterval(progressInterval);
      setLoadingProgress(0);
    }
  };

  return {
    isGeneratingPDF,
    loadingProgress,
    handleDownloadPDF,
  };
};

const convertTablesToBuffer = (tables: Table[]): ArrayBuffer => {
  const stringifiedData = JSON.stringify(tables);
  const buffer = new TextEncoder().encode(stringifiedData).buffer;
  return buffer;
};
